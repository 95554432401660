import DxThemes from 'devextreme/ui/themes';
import HomeService from '@Services/home.service';

export default class ThemeConfig {
  private readonly CheckboxTheme = document.querySelector(
    'input[id="switch_id"][type="checkbox"]'
  ) as HTMLInputElement | undefined;

  private toggleDxTheme(theme: 'dark' | 'light'): void {
    DxThemes.current(`generic.${theme}.compact`);
  }

  public toggleApplicationTheme = async (): Promise<void> => {
    const checked = this.CheckboxTheme?.checked ?? false;
    this._setDarkMode(checked);
    const result = await HomeService.changeTheme(checked);
    if (!result) this._setDarkMode(false);
  };

  private _setDarkMode(dark: boolean): void {
    const body = document.body;
    if (dark) {
      body.classList.add('night');
      this.toggleDxTheme('dark');
    } else {
      body.classList.remove('night');
      this.toggleDxTheme('light');
    }
  }

  public applyCurrentTheme(): void {
    const checked = this.CheckboxTheme?.checked ?? false;
    this._setDarkMode(checked);
  }

  public initialize(): void {
    this.CheckboxTheme?.addEventListener('click', this.toggleApplicationTheme);
    this.applyCurrentTheme();
  }
}
