import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import { confirm, alert, custom } from 'devextreme/ui/dialog';
import { ClickEvent, dxButtonOptions } from 'devextreme/ui/button';

import { RegexStrHtml } from '@Shared/constants/regexp';

export default class NotificationService {
  constructor() {
    loadMessages(esMessages);
    locale('es-Es');
  }

  public notify(type: NotificationType, message: string): void {
    const title: string = this.defaultTitle[type];
    mostrarMensaje(title, message, type !== 'warning' ? type : 'notice');
  }

  private defaultTitle: Record<NotificationType, string> = {
    error: '¡Error!',
    info: 'Información',
    success: '¡Éxito!',
    warning: '¡Advertencia!'
  };

  public confirm(title: string, message: string): Promise<boolean> {
    return confirm(this.getMessageBody(message), title);
  }

  public alert(title: string, message: string): Promise<void> {
    return alert(this.getMessageBody(message), title);
  }

  public async customAlert(
    options: CustomAlertOptions
  ): Promise<CustomAlertData> {
    const { title, buttons, message } = options;
    const defaultbuttons = buttons ?? this.getCustomButtons();
    const dialog = custom({
      title,
      buttons: defaultbuttons,
      messageHtml: this.getMessageBody(message),
      dragEnabled: true,
      showTitle: true,
    });

    return (await dialog.show()) as CustomAlertData;
  }

  private getDefaultHtmlTemplate(message: string): string {
    return `<div style="text-align: center;">${message}<div>`;
  }

  private getMessageBody(message: string): string {
    const hasWrapperContainer = RegexStrHtml.isContentWrapped(message);
    return hasWrapperContainer ? message : this.getDefaultHtmlTemplate(message);
  }

  private getCustomButtons(): Array<ButtonOptions> {
    return [
      {
        text: 'Aceptar',
        type: 'normal',
        onClick: (event: ClickEvent) =>
          this.mapButtonOptions(event.component.option()),
      },
      {
        text: 'Cancelar',
        type: 'normal',
        onClick: (event: ClickEvent) =>
          this.mapButtonOptions(event.component.option()),
      },
    ];
  }

  private mapButtonOptions = (options: dxButtonOptions): CustomAlertData => ({
    button: options.text as string,
  });
}

export type NotificationType = 'error' | 'info' | 'success' | 'warning';
export interface CustomAlertOptions {
  title: string;
  message: string;
  buttons?: Array<ButtonOptions>;
}

export interface ButtonOptions {
  text: string;
  type: 'back' | 'danger' | 'default' | 'normal' | 'success';
  onClick?: (_event: ClickEvent) => CustomAlertData;
  icon?: string;
}

export interface CustomAlertData {
  button: string;
}
