import Deferred from '@Utils/Deferred';
import { ModulesKeys } from '@Shared/types/common.cd';

export interface FlemingRegistry {
  define(name: string, constructor: Constructable): void;
  get(name: string): Constructable | undefined;
  has(name: string): boolean;
  whenDefined(name: string): Promise<Constructable>
}

interface Constructable {
  new (...args: unknown[]): unknown
}

interface Definition {
  name: string,
  constructor: Constructable
}

type ModuleName = ModulesKeys | 'root';

export class FlemingModule implements FlemingRegistry {
  private readonly _definitions: Definition[] = [];
  private readonly _promisedDefinitionQueue: Map<string, Deferred<Constructable>>;

  constructor() {
    this._promisedDefinitionQueue = new Map();
  }

  define(name: string, constructor: Constructable): void {
    const definitionFounded = this._definitions.find((definition) => definition.name === name || definition.constructor === constructor);
    if(definitionFounded?.name === name) throw new Error(`${name} ya ha sido definido`);
    if(definitionFounded?.constructor === constructor) throw new Error(`${name} y ${definitionFounded?.name} tienen el mismo constructor`);

    this._definitions.push({ name, constructor });
    if (this._promisedDefinitionQueue.has(name)) {
      const deferred = this._promisedDefinitionQueue.get(name);
      deferred?.resolve(constructor);
      this._promisedDefinitionQueue.delete(name);
    }
  }

  whenDefined(name: string): Promise<Constructable> {
    if (this.has(name)) {
      const constructor = this.get(name);
      if(!constructor) throw new Error('El registro esta corrupto');

      return Promise.resolve<Constructable>(constructor);
    }
    if (this._promisedDefinitionQueue.has(name)) {
      const constructorDeferred = this._promisedDefinitionQueue.get(name);
      if(!constructorDeferred) throw new Error('Error en la cola de constructores prometidos');

      return constructorDeferred.promise;
    }
    const deferred = new Deferred<Constructable>();
    this._promisedDefinitionQueue.set(name, deferred);
    return deferred.promise;
  }

  get(name: string): Constructable | undefined {
    return this._definitions.find((definition) => definition.name === name)?.constructor;
  }

  has(name: string):boolean {
    return this._definitions.some((definition) => definition.name === name);
  }
}

export class FlemingContainer {
  private readonly _modules = new Map<ModuleName, FlemingModule>();

  constructor() {
    this._modules.set('root', new FlemingModule());
  }

  define(name: string, constructor: Constructable, module: ModuleName = 'root'): void {
    if(!this._modules.has(module)) {
      this._modules.set(module, new FlemingModule());
    }
    this._modules.get(module)?.define(name, constructor);
  }

  whenDefined(name: string, module: ModuleName = 'root'): Promise<Constructable> {
    if(!this._modules.has(module)) {
      this._modules.set(module, new FlemingModule());
    }

    const moduleRegistry = this._modules.get(module);
    if(!moduleRegistry) throw new Error('Módulo no encontrado');

    return moduleRegistry.whenDefined(name);
  }

  get(name: string, module: ModuleName = 'root'): Constructable | undefined {
    if(!this._modules.has(module)) return undefined;

    return this._modules.get(module)?.get(name);
  }

  has(name: string, module: ModuleName = 'root'):boolean {
    if(!this._modules.has(module)) return false;

    const moduleRegistry = this._modules.get(module);
    if(!moduleRegistry) throw new Error('Módulo no encontrado');

    return moduleRegistry.has(name);

  }
}
