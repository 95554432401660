import * as qs from 'qs';
import { PathLike } from 'fs';
import { AxiosRequestConfig } from 'axios';

export const apiConfig: AxiosRequestConfig = {
  withCredentials: false,
  timeout: 60000,
  baseURL: window.origin,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (params: PathLike) =>
    qs.stringify(params, { indices: false }),
};
