import ThemeConfig from '@Utils/theme.config';
import CommonService from '@Services/common.service';
import NotificationService from '@Services/notification.service';
import { FlemingContainer } from '@Utils/fleming_register/FlemingRegistry';

window.notificationService = new NotificationService();

CommonService.documentReady(() => {
  new ThemeConfig().initialize();
});

window.Fleming || (window.Fleming = new FlemingContainer());


