import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  AxiosError,
} from 'axios';

import { apiConfig } from './axios.config';
import { interceptor } from './axios.types';
import CommonService from '@Services/common.service';
import { HttpStatusCode as StatusCodes } from '@Shared/types/http_status_codes.cd';
import NotificationService from '@Services/notification.service';
import { errorGuard } from '@Shared/guards';

class HttpService {
  private readonly http: AxiosInstance;
  private readonly notificationService: NotificationService;

  constructor() {
    this.http = axios.create(apiConfig);
    this.notificationService = new NotificationService();
    this.http.interceptors = this.setupInterceptors(this.http.interceptors);
  }

  private setupInterceptors(interceptor: interceptor): interceptor {
    interceptor.request.use(
      (param) => {
        return {
          ...param,
          headers: {
            ...param.headers,
            'X-CSRF-Token': CommonService.getToken(),
            EstablecimientoToken: CommonService.getCurrentEstablishment(),
          },
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    interceptor.response.use(
      (param) => ({
        ...param,
      }),
      (error) => {
        this.errorHandling(error);
        return Promise.reject(error);
      }
    );
    return interceptor;
  }

  private errorHandling(error: AxiosError | unknown): void {
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status;
      switch (statusCode) {
        case StatusCodes.Forbidden:
          this.notificationService.notify(
            'error',
            'No esta autorizado para ver este recurso'
          );
          break;

        case StatusCodes.Unauthorized:
        case StatusCodes.MethodNotAllowed:
        case StatusCodes.UnprocessableEntity:
          window.location.href = '/';
          break;

        case StatusCodes.InternalServerError:
        case StatusCodes.HttpVersionNotSupported:
          this.notificationService.notify(
            'error',
            'Ups! Algo salió mal en el servidor'
          );
          break;

        default:
          this.notificationService.notify(
            'error',
            'Ha ocurrido un error al hacer la petición'
          );
          break;
      }
    } else {
      if (errorGuard(error))
        this.notificationService.notify('error', error.message);
    }
  }

  public request<T = unknown, D = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.request<T, R, D>(config);
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.http.getUri(config);
  }

  public get<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.get<T, R, D>(url, config);
  }

  public post<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.post<T, R, D>(url, data, config);
  }

  public delete<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.delete<T, R, D>(url, config);
  }

  public head<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.head<T, R, D>(url, config);
  }

  public options<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.options<T, R, D>(url, config);
  }

  public put<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.put<T, R, D>(url, data, config);
  }

  public patch<T = unknown, D = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.patch<T, R, D>(url, data, config);
  }
}

export default new HttpService();
