import { GuardedType, Sentinel } from '@Shared/types/common.cd';

export const errorGuard = (error: unknown): error is Error => {
  const isObject = genericObjectGuard(error);
  return isObject ? 'message' in error : false;
};

export const genericObjectGuard = (object: unknown): object is object => {
  return typeof object === 'object';
};

export const customObjectGuard = <T>(
  searchProperty: keyof T,
  object: unknown
): object is T => {
  const isObject = genericObjectGuard(object);
  return isObject ? searchProperty in object : false;
};

export default function typeGuard<T extends Sentinel>(
  value: unknown,
  sentinel: T
): value is GuardedType<T> {
  return typeof value === sentinel;
}