export class RegexStrHtml {
  public static containsTags = /<\/?[a-z][\s\S]*>/i;
  public static startWithTag = /^<[a-z][\s\S]*>/i;
  public static endsWithTag = /<\/[a-z][\s\S]*>$/i;

  public static isContentWrapped = (stringHtml: string): boolean => {
    const startWithTag: boolean = RegexStrHtml.startWithTag.test(stringHtml);
    const endsWithTag: boolean = RegexStrHtml.endsWithTag.test(stringHtml);

    if(startWithTag && endsWithTag) {
      const starttag: string = stringHtml.substring(1, 2);
      const endtag: string = stringHtml.substring(stringHtml.length -2, stringHtml.length -1);

      return starttag.toLowerCase() === endtag.toLowerCase();
    }

    return false;
  };

  public static startWith = (tagname: string): RegExp => new RegExp(`^<${tagname}*>`, 'i');
  public static endsWith = (tagname: string): RegExp => new RegExp(`<${tagname}*>$`, 'i');
}
