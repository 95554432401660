import http from '@Utils/axios/http.service';

export default class HomeService {
  public static async changeTheme(toggled: boolean): Promise<boolean> {
    const axiosResponse = await http.post<
      { result: boolean },
      { accion: boolean }
    >('/home/cambiar_tema', {
      accion: toggled,
    });

    return axiosResponse.data.result;
  }
}
