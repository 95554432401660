export default class Deferred<T> {
  private _resolve!: (value: T) => void;
  private _reject!: (reason?: unknown) => void;
  private readonly _promise: Promise<T>;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._reject = reject;
      this._resolve = resolve;
    });
  }

  public get promise(): Promise<T> {
    return this._promise;
  }

  public resolve(value: T): void {
    this._resolve(value);
  }

  public reject(reason?: unknown): void {
    this._reject(reason);
  }
}
