import { ComponentProps, ComponentsName, ModulesKeys } from '@Shared/types/common.cd';

export default class CommonService {
  public static getCurrentEstablishment(): string {
    const input = document.getElementById(
      'hfEstablecimientoActual'
    ) as HTMLInputElement;
    return input.value ?? '';
  }

  public static getToken(): string {
    const tokenMeta: Element | null = document.querySelector(
      'meta[name="csrf-token"]'
    );
    const token: string = tokenMeta
      ? (tokenMeta.getAttribute('content') as string)
      : '';
    return token;
  }

  /**
   * Get a single react component with its events and instance.
   * @param properties specify the component id stablished on the html react tag and the current module & submodule where is located the component
   */
  public static getCurrentComponentProps<Events, Component>(properties: {
    id: string;
    module: ModulesKeys;
    submodule: string;
    component: ComponentsName;
  }): ComponentProps<Events, Component> | undefined {
    const { id, module, submodule, component } = properties;
    const app = window.application;
    const currentModule = app[module];
    const currentSubmodule = currentModule
      ? currentModule[submodule]
      : undefined;
    const currentComponents = currentSubmodule
      ? currentSubmodule.reactComponents
      : undefined;
    const currentComponent = currentComponents
      ? currentComponents[component]
      : undefined;
    return currentComponent
      ? (currentComponent[id] as ComponentProps<Events, Component>)
      : undefined;
  }

  /**
   * Execute when the DOM is fully loaded.
   * @param callback A function to execute after the DOM is ready.
   */
  public static documentReady(callback: () => unknown): void {
    const loader = setInterval(() => {
      if (document.readyState !== 'complete') return;

      clearInterval(loader);
      callback();
    }, 300);
  }
}
